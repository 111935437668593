import axios from 'axios'
import store from '../store'
import router from '../router'

var webUrl = window.location.href
var origin = new URL(webUrl).origin

const domain = 'https://mydevices.myweb.net.au'
const ip = 'http://137.184.112.173:5002'
const url = origin.includes('localhost') || origin.includes('137.184.112.173') ? ip : origin
const baseURL = url + '/api/'

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-type': 'application/json'
  },
})

instance.interceptors.request.use(
  config => {
    if (store.getters['authUser/isLoggedIn']) {
      config.headers.Authorization = 'Bearer ' + store.getters['authUser/token']
    }
    return config
  },
  error => Promise.reject(error),
)

instance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config
    const status = error?.response?.status

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (store.getters['authUser/isRefreshing']) {
        store
          .dispatch('authUser/getAccessToken')
          .then(() => {
            originalRequest.headers.Authorization = 'Bearer ' + store.getters['authUser/token']
            return instance.request(originalRequest)
          })
          .catch(() => {
            router.push('/login')
          })
      } else {
        router.push('/login')
      }
    }

    return Promise.reject(error)
  },
)

export default instance
