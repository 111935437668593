<template>
  <data-table-wrapper
    v-bind="$attrs"
    :headers="headers"
    v-on="$listeners"
  >
    <template
      v-for="(header,i) in headers"
      v-slot:[`item.${header.value}`]="{ item }"
    >
      <span
        :key="i"
        @click="onClick(header, item)"
        v-html="getValue(header, item)"
      />
    </template>

    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>

    <template v-slot:top>
      <slot name="top" />

      <v-dialog v-model="dialog.show">
        <v-card>
          <v-card-title>
            <div
              class="w-100 d-flex flex-row justify-space-between align-center"
              style="width:100%"
            >
              <h2>{{ dialog.title }}</h2>
              <v-btn
                x-small
                color="error"
                fab
                @click="dialog.show = false"
              >
                <v-icon class="cursor-pointer">
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <div
              style="overflow: auto;"
              v-html="dialog.content"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </data-table-wrapper>
</template>

<script>
  export default {
    name: 'AutoTable',
    data: () => ({
      dialog: {
        show: false,
        title: '',
        content: '',
      },
    }),
    computed: {
      headers () {
        const items = this.$attrs?.items
        if (!items) {
          return []
        }

        return this.getHeaders(items)
      },
    },
    methods: {
      getValue (header, item) {
        const value = item[header.value]
        if (!value) {
          return ''
        }

        if (typeof value !== 'object') {
          return value
        }

        if (Array.isArray(value)) {
          return `<a style="color: #4081ed; font-weight:500;">(${value.length})</a>`
        }

        const values = Object.keys(value).map(key => `<div style="font-weight: 500">${this.camelToPascalWithSpaces(key)}: <span style="font-weight: 400;">${value[key]}</span></div>`).join('')
        return `<div style="display: grid; grid-template-columns: auto auto auto; gap: 10px; padding: 10px 5px">${values}</div>`
      },

      onClick (header, item) {
        const value = item[header.value]

        if (Array.isArray(value)) {
          const headers = this.getHeaders(value)
          const headersContent = headers.map(header => `<div style="font-weight: 500;white-space: nowrap;">${header.text}</div>`).join('')
          const itemsContent = value.map(item => Object.keys(item).map(key => `<div style="font-weight: 400;white-space: nowrap;">${item[key]}</div>`).join('')).join('')

          this.dialog.content = `<div style="display: grid; grid-template-columns: repeat(${headers.length}, 1fr); gap: 20px">${headersContent + itemsContent}</div>`
          this.dialog.title = header.text
          this.dialog.show = true
        }
      },

      getHeaders (items) {
        const item = items?.[0]
        if (!item) {
          return []
        }

        return Object.keys(item).map(key => ({ text: this.camelToPascalWithSpaces(key), value: key }))
      },
      camelToPascalWithSpaces (str) {
        const pascalCase = str.charAt(0).toUpperCase() + str.slice(1)
        const result = pascalCase.replace(/[A-Z]/g, match => ' ' + match)
        return result
      },
    },
  }
</script>
