import moment from "moment";
var _currencyType = "Rs."
export default {
    date(date) {
        if (date) {
            return moment.utc(date).local().format("DD.MM.YYYY");
        } else {
            return "";
        }
    },

    dateTime(dateTime) {
        if (dateTime) {
            return moment.utc(dateTime).local().format("DD.MM.YYYY h:mm:ss a");
        } else {
            return "";
        }
    },

    currency(_currency) {
        if (typeof _currency === 'number') {
            var formatter = new Intl.NumberFormat('en-IN');
            return _currencyType + formatter.format(_currency);
        } else {
            return _currencyType + 0;
        }
    },
}