<template>
  <div>
    <v-text-field v-if="type === TYPES.INT || type === TYPES.LONG || type === TYPES.FLOAT || type === TYPES.DOUBLE"
      :value="value" :label="label" type="number" hide-details @input="update" />

    <v-select v-if="type === TYPES.BOOLEAN" :value="value" :items="['true', 'false']" :label="label" hide-details
      @input="update" />

    <v-text-field v-else :value="value" :label="label" hide-details @input="update" />
  </div>
</template>

<script>
import { TYPES } from './types'

export default {
  props: ['value', 'label', 'type'],
  data() {
    return {
      TYPES,
    }
  },
  methods: {
    update(val) {
      this.$emit('input', val)
    },
  },
}
</script>
