import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import i18n from './i18n'
import VueIziToast from 'vue-izitoast'
import 'izitoast/dist/css/iziToast.css'
import { sync } from 'vuex-router-sync'
import Signal from './signal'
import axios from './util/http-common'
import formatter from './util/formatter'

import DataTableWrapper from './views/components/custom/DataTableWrapper.vue'
import AutoTable from './views/components/custom/AutoTable.vue'
import EditableTable from './views/components/custom/EditableTable.vue'
import UserPrompt from './views/components/custom/UserPrompt.vue'
import Dropzone from './views/components/custom/Dropzone.vue'
import FormDialog from './views/components/custom/FormDialog.vue'

Vue.component('data-table-wrapper', DataTableWrapper)
Vue.component('auto-table', AutoTable)
Vue.component('editable-table', EditableTable)
Vue.component('user-prompt', UserPrompt)
Vue.component('dropzone', Dropzone)
Vue.component('form-dialog', FormDialog)

Vue.prototype.$http = axios
Vue.prototype.$resourceUrl = (path) => {
  return axios.defaults.baseURL.replace('api/', 'Resources') + path
}

Vue.prototype.$format = formatter

Vue.config.productionTip = false
Vue.use(VueIziToast)
Vue.use(Signal)
sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  VueIziToast,
  i18n,
  render: h => h(App),
}).$mount('#app')
