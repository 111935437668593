import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr'
import axios from '@/util/http-common'
import store from '@/store'
import hyperid from 'hyperid'

const BROWSER_ID_KEY = "BROWSER_ID_KEY"
// connecting: 0, connected: 1, reconnecting: 2, disconnected: 4
export default {
  install(Vue) {
    var signalUrl = axios.defaults.baseURL.replace('api/', 'device-hub')
    var hubConnection = null

    const signalHub = new Vue()
    Vue.prototype.$signalHub = signalHub

    const _signal = {
      setupSignalConnection() {
        hubConnection = new HubConnectionBuilder()
          .withUrl(signalUrl)
          .withAutomaticReconnect([0, 5000, 10000, 30000])
          .configureLogging(LogLevel.Information)
          .build()

        hubConnection.on('RemoteAssistanceAnswer', (id, answer) => {
          //console.log('onRemoteAssistanceAnswer', id, answer);
          signalHub.$emit('RemoteAssistanceAnswer', JSON.parse(answer))
        })

        hubConnection.on('RemoteAssistanceCandidate', (id, candidate) => {
          //console.log('onRemoteAssistanceCandidate', id, candidate);
          signalHub.$emit('RemoteAssistanceCandidate', JSON.parse(candidate))
        })

        hubConnection.onreconnecting(error => {
          this.updateSignalStatus()
          console.assert(hubConnection.state === HubConnectionState.Reconnecting)
          console.log('Signal is Reconnecting...', error)
        })

        hubConnection.onreconnected(connectionId => {
          this.updateSignalStatus()
          console.assert(hubConnection.state === HubConnectionState.Connected)
          console.log('Signal Reconnected with ' + connectionId)
          this.sendDeviceId()
        })

        hubConnection.onclose(error => {
          this.updateSignalStatus()
          console.assert(hubConnection.state === HubConnectionState.Disconnected)
          console.log('Signal Disconnected. ', error)
        })

        return this.startSignalConnection(hubConnection)
      },

      sendDeviceId() {
        try {
          let id = localStorage.getItem(BROWSER_ID_KEY);
          if (!id) {
            id = hyperid()()
            localStorage.setItem(BROWSER_ID_KEY, id);
          }

          hubConnection.send('AddDeviceId', id)
        } catch (error) {
          console.log('AddDeviceId', error);
        }
      },

      async sendRemoteAssistanceOffer(deviceId, offer) {
        return await hubConnection.invoke('RemoteAssistanceOffer', deviceId, JSON.stringify(offer))
      },

      async sendRemoteAssistanceCandidate(deviceId, candidate) {
        hubConnection.send('RemoteAssistanceCandidate', deviceId, JSON.stringify(candidate))
      },

      async sendRemoteAssistanceCandidates(deviceId, candidates) {
        hubConnection.send('RemoteAssistanceCandidate', deviceId, JSON.stringify(candidates))
      },

      async startSignalConnection(connection) {
        try {
          await connection.start()
          this.updateSignalStatus()
          console.assert(connection.state === HubConnectionState.Connected)

          this.sendDeviceId()
          return Promise.resolve('Signal Connected.')
        } catch (err) {
          this.updateSignalStatus()
          axios.get('/SMS/SendErrorMessage', {
            params: {
              message: 'Connection attempt from ' + store.state.authUser.username + ' failed in vue.js.  error:' + err,
            },
          }).then(response => {

          })
          console.assert(connection.state === HubConnectionState.Disconnected)
          setTimeout(() => this.startSignalConnection(connection), 5000)
          return Promise.reject(new Error('Signal not Connected'))
        }
      },

      connectionRequests: [],
      getConnection(listener) {
        if (hubConnection != null && hubConnection.state === HubConnectionState.Connected) {
          listener(hubConnection)
        } else {
          this.connectionRequests.push(listener)
        }
      },

      updateSignalStatus() {
        if (hubConnection != null && hubConnection.state === HubConnectionState.Connected) {
          signalHub.$emit('signal-status-changed', true)

          if (this.connectionRequests?.length) {
            while (this.connectionRequests?.length) {
              const listener = this.connectionRequests.pop()
              listener?.();
            }
          }
        } else {
          signalHub.$emit('signal-status-changed', false)
        }
      },
    }

    _signal.setupSignalConnection()
    Vue.prototype.$signal = _signal
  },
}
