import http from '@/util/http-common'

class UserDataService {
  getAll(id) {
    return http.get(`/users/${id}`)
  }

  create(data) {
    return http.post('/users', data)
  }

  update(data) {
    return http.post('/Users/Update', data)
  }

  delete(id) {
    return http.post('/Users/Delete', null, { params: { Id: id } })
  }

  getAllRoles() {
    return http.get('/users/roles')
  }

  CreateRole(data) {
    return http.post('Roles/AddRole', null, { params: { roleName: data } })
  }

  DeleteRole(roleName) {
    return http.get('/Roles/Delete', { params: { roleName: roleName } })
  }

  getRolePermissions(data) {
    return http.get('/users/RolePermissions', { params: { roleId: data } })
  }

  updateRolePermissions(data) {
    return http.post('/users/RolePermissions', data)
  }

  login(data) {
    return http.post('/users/login', data)
  }

  getAccessToken(data) {
    return http.post('/Users/RefreshToken', data)
  }

  logout(data) {
    return http.post('/Users/RevokeToken', data)
  }

  version() {
    return http.get('/version')
  }
}

export default new UserDataService()
