<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
// Styles
  import '@/styles/overrides.sass'
  import '@/styles/styles.css'

  export default {
    name: 'App',
    metaInfo: {
      title:
        'MyDevices | SoftClient.com.au: supplier of barcode and RFID scanners &amp; printers',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  }
</script>
