import { make } from 'vuex-pathify'
import Vue from 'vue'
import axios from '../../util/http-common'

const state = {
  baseConfigCompanyId: 1,
  baseConfigs: {

  }
}

const mutations = {
  ...make.mutations(state),
  updateBaseConfigs(state, configs) {
    state.baseConfigs = configs?.reduce((result, item) => {
      result[item.name] = item
      return result
    }, {}) || {}
  },
}

const actions = {
  ...make.actions(state),

  loadBaseConfigurations: async ({ commit, state }) => {
    if (Object.keys(state.baseConfigs).length) {
      return
    }

    try {
      const response = await axios.get('CompanyConfiguration', {
        params: {
          companyId: state.baseConfigCompanyId
        },
      })

      const configs = response.data?.data
      if (configs.length) {
        commit('updateBaseConfigs', configs)
        return configs;
      }
    } catch (error) {

    }

    return []
  },
}

const getters = {
  getConfigNames: state => {
    return Object.keys(state.baseConfigs)
  },
  getConfig: state => name => {
    return state.baseConfigs[name]
  },
  isBaseCompany: state => id => {
    return state.baseConfigCompanyId === id
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
