import { make } from 'vuex-pathify'
import Vue from 'vue'
import axios from '../../util/http-common'

const state = {
  baseCompanyId: 1,
  fileGroups: {

  }
}

const mutations = {
  ...make.mutations(state),
  updateFileGroups(state, groups) {
    groups?.forEach(x => {
      const group = state.fileGroups[x.name] || {}
      group[x.id] = { companyId: x.companyId, files: [] }

      Vue.set(state.fileGroups, x.name, group)
    });
  },
  updateGroupFiles(state, { groupName, groupId, files }) {
    const group = state.fileGroups[groupName] || {}
    const company = group[groupId] ??= { files: [] }
    company.files.push(...files)

    Vue.set(state.fileGroups, groupName, group)
  }
}

const actions = {
  ...make.actions(state),

  loadFileGroups: async ({ commit, state }, companyId) => {
    try {
      if (!Object.keys(state.fileGroups[state.baseCompanyId] || {}).length) {
        const groups = await getFileGroups(state.baseCompanyId)
        if (groups.length) {
          commit('updateFileGroups', groups)
        }
      }

      if (companyId > 0 && !Object.keys(state.fileGroups[companyId] || {}).length) {
        const groups = await getFileGroups(companyId)
        if (groups.length) {
          commit('updateFileGroups', groups)
        }
      }
    } catch (e) {

    }
  },
  loadGroupFiles: async ({ commit, state }, groupName) => {
    try {
      const group = state.fileGroups[groupName] || {}
      const groupIds = Object.keys(group);

      for (const groupId of groupIds) {
        if (group[groupId]?.files?.length) {
          continue
        }

        const files = await getGroupFiles(groupId)
        if (files.length) {
          commit('updateGroupFiles', { groupName, groupId, files })
        }
      }
    } catch (e) {

    }
  }
}

const getters = {
  getFileGroups: state => {
    return Object.keys(state.fileGroups || {})
  },
  getGroupFiles: state => groupName => {
    return Object.values(state.fileGroups[groupName] || {}).map(x => x.files).flat()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

async function getFileGroups(CompanyId) {
  try {
    const response = await axios.get('DeviceConfig/GetDeviceConfigsByCompany', {
      params: {
        CompanyId
      },
    })

    return response.data || []
  } catch (error) {
    return []
  }
}

async function getGroupFiles(DeviceConfigId) {
  try {
    const response = await axios.get('DeviceConfig/GetFilePathsByDeviceConfigs', {
      params: {
        DeviceConfigId
      },
    })

    return response.data || []
  } catch (error) {
    return []
  }
}
