import axios from '../../util/http-common'

const state = {
  username: localStorage.getItem('username') || '',
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  permissions: localStorage.getItem('permissions') || [],
  isRefreshing: true,
}

const mutations = {
  auth_success (state, user) {
    state.username = user.user
    state.token = user.token
    state.refreshToken = user.refreshToken
    state.permissions = user.permissions

    localStorage.setItem('username', user.user)
    localStorage.setItem('token', user.token)
    localStorage.setItem('refreshToken', user.refreshToken)
    localStorage.setItem('permissions', user.permissions)

    try {
      this._vm.$signal.setupSignalConnection()
    } catch (error) {
      console.log('authUser: startSignal:', error)
    }
  },

  auth_error (state) {
    state.username = ''
    state.token = ''
    state.refreshToken = ''
    state.permissions = []

    localStorage.removeItem('username')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('permissions')
  },
}

const actions = {
  authenticate: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('Authentications/Authenticate')
        .then(response => {
          resolve()
        })
        .catch(e => {
          commit('auth_error')
          reject(e)
        })
    })
  },

  login: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/users/login', user)
        .then(response => {
            if (response.data.success) {
              commit('auth_success', response.data.data)
              resolve(response.data)
            } else {
              commit('auth_error')
              reject(response.data)
            }
        })
        .catch(err => {
          commit('auth_error')
          reject(err)
        })
    })
  },

  getAccessToken: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      if (state.refreshToken) {
        axios
          .post('Authentications/RefreshToken', { token: state.refreshToken })
          .then(response => {
            commit('auth_success', response.data)
            resolve(response)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      } else {
        reject(new Error('Refresh token not found'))
      }
    })
  },

  logout: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('auth_error')
      resolve()
    })
  },
}

const getters = {
  isRefreshing: state => !!state.isRefreshing,
  isLoggedIn: state => !!state.token,
  token: state => state.token,
  permissions: state => state.permissions,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
