<template>
  <DropZone id="myVueDropzone" ref="myVueDropzone" :options="dropzoneOptions" @vdropzone-file-added="sendingFile"
    :style="{ borderRadius: '7.5px', marginTop: '5px', minHeight: `${height}px`, padding: `10px 10px` }">
    Hello
  </DropZone>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "vDropZone",
  components: { DropZone: vue2Dropzone },
  props: {
    value: File,
    height: { type: Number, default: 100 },
    placeholder: { type: String, default: "Drop files here to upload" }
  },
  data() {
    return {
      dropzoneOptions: {
        dictDefaultMessage: this.placeholder,
        autoProcessQueue: false,
        url: "http://localhost",
        previewsContainer: false,
      },
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.clearFileEl()
      }
    }
  },
  methods: {
    sendingFile(file) {
      this.$emit('input', file)
      this.$emit('drop', file)
      this.setSelectedFileName(file.name)
    },
    setSelectedFileName(name) {
      const el = this.createFileEl()
      el.innerHTML = name;
    },
    createFileEl() {
      const selectedFileEl = document.getElementById('dropzone:selected-file-name');
      if (selectedFileEl) {
        return selectedFileEl;
      }

      const containerEl = this.$refs.myVueDropzone.$el

      const fileEl = document.createElement('div')
      fileEl.id = 'dropzone:selected-file'
      fileEl.classList.add('dropzone-file')

      const fileIconEl = document.createElement('img')
      fileIconEl.classList.add('dropzone-file-icon')
      fileIconEl.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAN2AAADdgF91YLMAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAH5QTFRF////4ufw1d7p4ufw1N3p4ufw4ufw0tvo4ufw4ufwtL/L0dvn4ufwz9nmz9nm4ufwy9fknay6n667pLLArLnGsb3Ksb7Lv8rVwMnVxM7ZydXjytbjytbky9fkzNfl0Nrn0tnk0tvn1d7p19/o2eHs3OPt3+Xu4OXv4ebv4ufwuZ8dhQAAABF0Uk5TAAECJkZwgK+ztcDA5ujq9v1FDxYhAAABx0lEQVR42u3bC1LCQBAE0CDyUUCIIgqogCCy97+g8QNSkMBmutkttOcC/WonU5OqZJMkt6r1ZtsZ6jqhVKXWdbZKKYLKlbNWShHUHAAgCKpdCIAL6g4DwIImCkAFbRgAChwOwAQMACSgABABBwAISAC7gAUwC2gAq4AHMAqIAJuACTAJqACLgAswCMiA8gI2oLSADigr4ANKChDAHUOAAAYpQYAAHlKCAAHMbwkCBOCGKS6AAO4eF2AAN4S7AALc/HGATaMj1Osov4IBigThAAWCgIB8QUhAriAoIE8QFpAjCAzYF8CAaT89Uv3pIQEMOJqfCQ6dQXjAjiB4C3YFoR/CPUEUwLYgDmBLEAnwK4gF2AiiAdaCeIAfQUSAm8QGvMUGrGIDnADnC5h6vAT4rGYzwJy/83JyvoDoLdAUCCCAANoF2gWaAgEEEEC7QLtAUyCAAAJoF2gXaAoEEEAAwyd7LqD8Twt/DRC9BZoCAQQQ4P8B2qfJf8/ye16A5mkAiwzQ8QLUT5K/eskALS8Acu238PwXn/njSy/A5uLzcvY8olbDL3999Xv5xI0f3VwkvoKvy+8zbvy44Z2ffF//Z55/r9Mq6P8H8X8tr9QVyF0AAAAASUVORK5CYII='

      const fileTextEl = document.createElement('span')
      fileTextEl.id = 'dropzone:selected-file-name'
      fileTextEl.classList.add('dropzone-file-text')

      fileEl.append(fileIconEl)
      fileEl.append(fileTextEl)
      containerEl.append(fileEl)

      return fileTextEl;
    },
    clearFileEl() {
      const selectedFileEl = document.getElementById('dropzone:selected-file');
      if (selectedFileEl) {
        selectedFileEl.remove()
      }
    }
  }
}
</script>

<style>
.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dropzone:hover {
  background-color: white;
}

.dropzone .dz-message {
  margin: 0.75rem 0;
}

.dropzone-file {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f5f5;
  padding: 7.5px 12.5px;
  margin: 5px;
  border-radius: 7.5px;
}

.dropzone-file-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.dropzone-file-text {
  max-width: 125px;
  margin-left: 7.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}

.dropzone-file-text:hover {
  overflow: visible;
}
</style>
