<template>
  <data-table-wrapper :headers="headers" :items="items" v-bind="$attrs" v-on="$listeners">
    <template v-for="(header) in headers" v-slot:[`item.${header.value}`]="{ item }">
      <v-edit-dialog :key="header.value" :return-value.sync="item[header.value]" large @save="update(item)"
        @cancel="cancel">
        {{ item[header.value] }}
        <template v-slot:input>
          <type-field v-model="item[header.value]" :label="header.text" :type="header.type" />
        </template>
      </v-edit-dialog>
    </template>

    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </data-table-wrapper>
</template>

<script>
import TypeField from './TypeField.vue';
export default {
  name: 'AutoTable',
  components: { TypeField },
  props: {
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
  },
  methods: {
    update(item) {
      console.log(item)

      this.$emit('item', item)
      this.$emit('update:items', this.items)
    },
    cancel() {

    },
  },
}
</script>
