var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"footer-props":{
    showFirstLastPage: true,
    'items-per-page-options': _vm.itemsPerOptions
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.typeSlots),function(slot,i){return {key:("item." + (slot.value)),fn:function(ref){
  var item = ref.item;
return [_c('span',{key:i,domProps:{"innerHTML":_vm._s(_vm.typeConversion(slot.type, item[slot.value]))},on:{"click":function($event){return _vm.onClick(slot, item)}}})]}}}),_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"top",fn:function(){return [_vm._t("top"),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"w-100 d-flex flex-row justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('h2',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-btn',{attrs:{"x-small":"","color":"error","fab":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{staticClass:"cursor-pointer"},[_vm._v(" mdi-close ")])],1)],1)]),_c('v-card-text',[_c('pre',[_vm._v(_vm._s(_vm.dialogContent))])])],1)],1)]},proxy:true}],null,true)},'v-data-table',Object.assign({}, _vm.$attrs, (_vm.serverSideRendering && ( _obj = {}, _obj['server-items-length'] = _vm.totalServerItems, _obj ))),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }