<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h5">
        Are you sure you want to delete this item?
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDelete"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="deleteItemConfirm"
        >
          OK
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: Object,
    },
    data () {
      return {
        dialog: false,
        userData: null,
      }
    },
    watch: {
      dialog (val) {
        if (val === false) {
          this.closeDelete()
        }
      },
    },
    created () {
      this.$emit('input', {
        show: (data) => {
          this.show()
          this.userData = data
        },
        close: this.closeDelete,
      })
    },
    methods: {
      show () {
        this.dialog = true
      },
      closeDelete () {
        this.dialog = false
        this.userData = null
      },
      deleteItemConfirm () {
        this.$emit('confirm', this.userData)
        this.closeDelete()
      },
    },
  }
</script>
