// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Devices',
      icon: 'mdi-account-group ',
      to: '/devices',
    },
    {
      title: 'Companies',
      icon: 'mdi-account-group ',
      to: '/companies',
    },
    {
      title: 'Device Logs',
      icon: 'mdi-account-group ',
      to: '/device-logs',
    },
    {
      title: 'Users',
      icon: 'mdi-account-group ',
      to: '/users',
    },
    {
      title: 'Logs',
      icon: 'mdi-clipboard-list',
      to: '/logs',
    },
    {
      title: 'Settings',
      icon: 'mdi-clipboard-list',
      to: '/settings',
    },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
